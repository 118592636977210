import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'recompose';
import { noop } from 'lodash';

import classNames from './radio.module.scss';

const {
  arrayOf, string, shape, any, func, node,
} = PropTypes;

const enhancer = compose(
  memo,
);

const Radio = ({
  options,
  value,
  onChange,
  className,
}) => (
  options.map(option => (
    <div key={option.value}>
      <label className={classNames.label}>
        <input
          type="radio"
          value={option.value}
          checked={`${option.value}` === value}
          className={cn(
            className,
            classNames.checkbox,
          )}
          onChange={onChange}
        />
        {option.label}
      </label>
    </div>
  ))
);

Radio.propTypes = {
  className: string,
  options: arrayOf(shape({ label: node, value: any })).isRequired,
  value: string,
  onChange: func,
};

Radio.defaultProps = {
  className: null,
  value: null,
  onChange: noop,
};

export default enhancer(Radio);
